import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import './MerchantOnboard.css'; // Custom CSS

const countries = [
  { name: "Bangladesh", flag: "https://flagcdn.com/w320/bd.png" },
  { name: "Italy", flag: "https://flagcdn.com/w320/it.png" },
  // Add more countries here
];

const MerchantOnboard = () => {
  const [country, setCountry] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const { t } = useTranslation(); // Hook for translation

  const handleCountryChange = (country) => {
    setCountry(country.name);
    setLoading(true);
    setDropdownOpen(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second loading time
  };

  const getIframeUrl = () => {
    if (country === "Bangladesh") {
      return "https://docs.google.com/forms/d/e/1FAIpQLSeFZZkIl_8GgrPqsn0IZBwNwppFtqPWFz0JzMZgddfP7BbXkA/viewform";
    } else if (country === "Italy") {
      return "https://forms.gle/2UAFVxYLM6fSG9FG9";
    }
    return "";
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('title')} | WEQUI</title>
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('grow_business_title')}</h2>
                <p className="subtitle">{t('subtitle')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb100 pt60">
        <div className="container">
          <div className="form-container">
            <h3>{t('start_journey_title')}</h3>
            <p>{t('welcome_message')}</p>
            
            {/* Custom Dropdown */}
            <div className={`custom-dropdown ${dropdownOpen ? 'open' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
              <div className="selected-country">
                {country ? (
                  <div className="selected-option">
                    <img src={countries.find(c => c.name === country).flag} alt={`${country} Flag`} className="country-flag-selected" />
                    <span className="country-name-selected">{country}</span>
                  </div>
                ) : (
                  <span>{t('select_country')}</span>
                )}
              </div>
              {dropdownOpen && (
                <div className="options-list">
                  {countries.map((c) => (
                    <div key={c.name} className="custom-option" onClick={() => handleCountryChange(c)}>
                      <img src={c.flag} alt={`${c.name} Flag`} className="country-flag" />
                      <span>{c.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {loading ? (
              <div className="loading-spinner">{t('loading_form')}</div>
            ) : (
              country && (
                <div className="google-form-container">
                  <iframe
                    id="google-form"
                    src={getIframeUrl()}
                    title={`Wequi Merchant Form for ${country}`} // Unique title for iframe
                    style={{
                      width: "100%",
                      minHeight: "1200px",
                      border: "none"
                    }}
                    scrolling="yes"
                  >
                    {t('loading_form')}
                  </iframe>
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MerchantOnboard;
