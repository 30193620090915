import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Brand = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('brand_page_title')} | WEQUI</title>
        <link rel="canonical" href="https://www.wequi.xyz/Brand" />
        <meta name="title" content={`${t('brand_page_title')} | WEQUI`} />
        <meta name="description" content={t('brand_intro_description')} />
        <meta name="keywords" content="WEQUI,WEQUI brand, brand guidelines WEQUI" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:url" content="https://www.wequi.xyz/Brand" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('brand_page_title')} | WEQUI`} />
        <meta property="og:description" content={t('brand_intro_description')} />
        <meta property="og:image" content="https://www.wequi.xyz/assets/images/logo.jpg" />

        <meta property="twitter:url" content="https://www.wequi.xyz/Brand" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('brand_page_title')} | WEQUI`} />
        <meta name="twitter:description" content={t('brand_intro_description')} />
        <meta name="twitter:image" content="https://www.wequi.xyz/assets/images/logo.jpg" />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('brand_page_title')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb100 pt60">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="job-details-info">
                <center>
                  <h2>{t('brand_intro_title')}</h2>
                  <br />
                  <p>{t('brand_intro_description')}</p>
                  <Link to="/assets/brand.pdf" className="btn btn-outline-dark">
                    {t('download_now')}
                  </Link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand;
