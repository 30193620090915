import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('privacy_page_title')} | WEQUI</title>
        <link rel="canonical" href="https://www.wequi.xyz/Privacy" />
        <meta name="description" content={t('privacy_page_title')} />
        <meta name="keywords" content="WEQUI privacy policy, GDPR, data protection, personal data" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:url" content="https://www.wequi.xyz/Privacy" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('privacy_page_title')} | WEQUI`} />
        <meta property="og:description" content={t('privacy_page_title')} />

        <meta property="twitter:url" content="https://www.wequi.xyz/Privacy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('privacy_page_title')} | WEQUI`} />
        <meta name="twitter:description" content={t('privacy_page_title')} />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('privacy_page_title')}</h2>
                <p align="justify">{t('privacy_last_updated')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb100 pt60">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="job-details-info">
                <p align="justify">{t('privacy_intro')}</p>

                <h3>{t('privacy_information_we_collect')}</h3>
                <p align="justify">{t('privacy_information_we_collect_desc')}</p>

                <h3>{t('privacy_how_we_use_info')}</h3>
                <p align="justify">{t('privacy_how_we_use_info_desc')}</p>

                <h3>{t('privacy_sharing_info')}</h3>
                <p align="justify">{t('privacy_sharing_info_desc')}</p>

                <h3>{t('privacy_security_info')}</h3>
                <p align="justify">{t('privacy_security_info_desc')}</p>

                <h3>{t('privacy_rights_choices')}</h3>
                <p align="justify">{t('privacy_rights_choices_desc')}</p>

                <h3>{t('privacy_childrens_privacy')}</h3>
                <p align="justify">{t('privacy_childrens_privacy_desc')}</p>

                <h3>{t('privacy_changes_policy')}</h3>
                <p align="justify">{t('privacy_changes_policy_desc')}</p>

                <h3>{t('privacy_contact_us')}</h3>
                <p align="justify">{t('privacy_contact_us_desc')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
