import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import Footer from './Component/Footer';
import Header from './Component/Header';
import Brand from './pages/Brand';
import Faq from './pages/Faq';
import Home from './pages/Home';
import Leadership from './pages/Leadership';
import Livechat from './pages/Livechat';
import NotFound from './pages/NotFound';
import Partner from './pages/Partner';
import Privacy from './pages/Privacy';
import Story from './pages/Story';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import MerchantOnboard from "./pages/MerchantOnboard"; // Import the component
import CampaignPage from './pages/CampaignPage';  // New Campaign Page
import OfferDetail from './pages/OfferDetail';    // New Dynamic Offer Detail Page

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  const { i18n } = useTranslation(); // Destructure i18n to get the current language

  // Detect the language and add or remove the bengali-font class to the body tag
  useEffect(() => {
    if (i18n.language === 'bn') { // Check if the language is Bengali
      document.body.classList.add('bengali-font'); // Add the bengali-font class
    } else {
      document.body.classList.remove('bengali-font'); // Remove it for other languages
    }
  }, [i18n.language]); // Re-run this effect whenever the language changes

  return (
    <div>
      <Router>
        <ScrollToTop>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/Brand' component={Brand} />
            <Route path='/Leadership' component={Leadership} />
            <Route path='/Livechat' component={Livechat} />
            <Route path='/Partner' component={Partner} />
            <Route path='/Privacy' component={Privacy} />
            <Route path='/Story' component={Story} />
            <Route path='/Terms' component={Terms} />
            <Route path='/Faq' component={Faq} />
            <Route path='/Contact' component={Contact} />
            <Route path="/MerchantOnboard" component={MerchantOnboard} />
            {/* New Campaign and Offer Detail Routes */}
            <Route path='/Campaign' exact component={CampaignPage} />  {/* Campaign Offers Listing */}
            <Route path='/Campaign/:permalink' component={OfferDetail} />  {/* Dynamic Offer Detail */}

            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
